import { RoutePage } from "src/shared/constants/route";
import { reduceUrlImage } from "src/shared/utils/common";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Box } from "@mui/material";
import ButtonLink from "src/components/ButtonLink";
import { FullWithImage } from "src/components/Carousel/Carousel";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import ScrollBrandingContent from "src/components/ScrollBrandingContent";

import useFetchHomePageInfo from "src/hooks/useFetchHomePageInfo";

const HomePage = () => {
  const { brandingContent, banners, isLoading } = useFetchHomePageInfo();

  if (isLoading) return <></>;

  return (
    <PageWrapper
      title="Trang chủ"
      actionArea={
        <ButtonLink to={`${RoutePage.HomePage}/upsert`} startIcon={<ModeEditOutlineOutlinedIcon />}>
          Chỉnh sửa trang chủ
        </ButtonLink>
      }>
      <PaperCustom>
        <Box overflow={"auto"}>
          <ScrollBrandingContent brandingContent={brandingContent} />

          <FullWithImage src={reduceUrlImage({ originUrl: banners, quality: 10 })} />

          {/* <Carousel urlList={carousels} /> */}
        </Box>
      </PaperCustom>
    </PageWrapper>
  );
};

export default HomePage;
