import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from "react";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Grid, Stack, Typography } from "@mui/material";

import useShowSnackbar from "src/hooks/useShowSnackbar";

const ImageInputSection = ({
  id = "add-image-input",
  setImage,
  defaultSrc,
  alt,
  multiple,
}: {
  id?: string;
  setImage?: Dispatch<SetStateAction<File | undefined>>;
  defaultSrc?: string;
  alt?: string;
  multiple?: boolean;
}) => {
  const showSnackBar = useShowSnackbar();
  const [imageSrc, setImageSrc] = useState<string>(defaultSrc || "");

  useEffect(() => {
    setImageSrc(defaultSrc || "");
  }, [defaultSrc]);

  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    if (e.target.files[0].size > 1024 * 500) {
      showSnackBar("Kích thước ảnh không được lớn hơn 500KB", "error");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setImageSrc(reader.result?.toString() || "");
    };

    setImage && setImage(e.target.files[0]);
  };

  return (
    <Grid
      item
      container
      width={"100%"}
      minHeight={"150px"}
      justifyContent={"center"}
      alignItems={"center"}
      position={"relative"}>
      <Grid item xs={12}>
        {imageSrc ? <img src={imageSrc} alt={alt} width={"100%"} height={"100%"} /> : null}
        <Stack
          component={"label"}
          htmlFor={id}
          style={{
            cursor: "pointer",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          {!imageSrc ? (
            <>
              <CloudUploadOutlinedIcon fontSize={"large"} />
              <Typography variant="body2">Tải Hình Ảnh</Typography>
            </>
          ) : null}
        </Stack>
        <input
          id={id}
          type="file"
          multiple={multiple}
          accept="image/jpeg,.jpeg,.jpg,image/png,.png,image/gif,.gif"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </Grid>
    </Grid>
  );
};

export default ImageInputSection;
