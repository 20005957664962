import { DefaultValues, useController, useForm } from "react-hook-form";
import { mutateHomePageValidateSchema } from "src/shared/types/form-validations";
import { HomePageForm } from "src/shared/types/forms";
import { isString, reduceUrlImage } from "src/shared/utils/common";

import { FormControl, FormHelperText, Grid } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import ImageInputSection from "src/components/ImageInput";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import { joiResolver } from "@hookform/resolvers/joi";
import useFetchHomePageInfo from "src/hooks/useFetchHomePageInfo";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUploadImage from "src/hooks/useUploadImage";
import useUpsertHomePage from "src/hooks/useUpsertHomePage";

interface HomePageUpsertFormProps {
  defaultValues?: DefaultValues<HomePageForm>;
}

const HomePageUpsertForm = ({ defaultValues }: HomePageUpsertFormProps) => {
  const defaultHomePageValues: DefaultValues<HomePageForm> = {
    scroll_branding_content: "",
    image_banner: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<HomePageForm>({
    defaultValues: defaultValues ?? defaultHomePageValues,
    resolver: joiResolver(mutateHomePageValidateSchema),
  });

  const {
    field: { onChange: onChangeImageBanner },
  } = useController({ name: "image_banner", control });

  // const [carouselImages, setCarouselImages] = useState<Array<UploadFile>>(
  //   (defaultValues?.image_carousels || []).map((imageURL, index) => ({
  //     uid: `uid-${index}`,
  //     name: `Link image: ${imageURL}`,
  //     status: "done",
  //     url: reduceUrlImage({ originUrl: imageURL, quality: 5, width: 250 }),
  //   }))
  // );

  const { mutateAsync: onAsyncUploadImage } = useUploadImage();

  const { mutate: onUpsertHomePage } = useUpsertHomePage();

  const showLoading = useShowOverlayLoading();

  return (
    <PageWrapper
      title={"Cập nhật trang chủ"}
      actionArea={
        <ActionButtonSection
          actionOnClick={handleSubmit(async (page) => {
            showLoading(true);

            const bannerImage = await onAsyncUploadImage(page.image_banner as File);

            // if (carouselImages.length) {
            //   const carouselImagesUploader: Promise<{ url: string }>[] = carouselImages.map(
            //     (file) =>
            //       file.status === "done"
            //         ? Promise.resolve({ url: file.url })
            //         : onAsyncUploadImage(file.originFileObj!).then((res) => ({
            //             url: res.data.secure_url,
            //           }))
            //   );
            //   await axios.all(carouselImagesUploader).then((dataResponses) => {
            //     const imagesPayload = dataResponses.map((data) => data.url);

            //     onUpsertHomePage({
            //       ...page,
            //       image_banner: bannerImage.data.secure_url,
            //       image_carousels: [...imagesPayload],
            //     });
            //   });
            // } else {
            onUpsertHomePage({
              ...page,
              image_banner: bannerImage.data.secure_url,
              image_carousels: [],
            });
            // }
          })}
          backTo={{ pathname: `/` }}
        />
      }>
      <Grid container spacing={3}>
        <Grid item md={12} lg={8}>
          <PaperContentSection title="Thông tin chung" topSection>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <TextFieldHF
                  required
                  multiline
                  minRows={3}
                  control={control}
                  name="scroll_branding_content"
                  placeholder="branding scroll"
                  labelOverride="Nội dung"
                />
              </Grid>
              {/* <Grid item xs={12}>
                <InputLabel>Carousels*</InputLabel>
                <Box display={"flex"}>
                  <PictureWallInput
                    fileList={carouselImages}
                    updateFileList={(images) => setCarouselImages(images)}
                    multiple
                    limitFile={20}
                  />
                </Box>
              </Grid> */}
            </Grid>
          </PaperContentSection>
        </Grid>
        <Grid item md={12} lg={4} width={"100%"}>
          <PaperContentSection title="Ảnh banner" topSection>
            <FormControl error={Boolean(errors.image_banner)} variant="outlined">
              <ImageInputSection
                setImage={onChangeImageBanner}
                defaultSrc={
                  isString(defaultValues?.image_banner)
                    ? reduceUrlImage({
                        originUrl: defaultValues?.image_banner,
                        quality: 10,
                        width: 370,
                      })
                    : undefined
                }
                alt="image_banner"
                id="image_banner"
              />
              {errors.image_banner?.message ? (
                <FormHelperText id="image-banner-error-text">
                  {errors.image_banner.message}
                </FormHelperText>
              ) : null}
            </FormControl>
          </PaperContentSection>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

const UpsertHomePage = () => {
  const { brandingContent, banners, carousels, isFetching } = useFetchHomePageInfo();

  if (isFetching || !brandingContent || !banners || !carousels) return <></>;

  return (
    <HomePageUpsertForm
      defaultValues={{
        scroll_branding_content: brandingContent,
        image_banner: banners,
        image_carousels: carousels,
      }}
    />
  );
};

export default UpsertHomePage;
